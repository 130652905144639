import { default as certificateL7co0lEgP4Meta } from "D:/workspace/web/roncoo-web/pages/account/certificate.vue?macro=true";
import { default as collectIqmM5CfUO2Meta } from "D:/workspace/web/roncoo-web/pages/account/collect.vue?macro=true";
import { default as couponLFC6dQyLrJMeta } from "D:/workspace/web/roncoo-web/pages/account/coupon.vue?macro=true";
import { default as course6Swd6tPaDAMeta } from "D:/workspace/web/roncoo-web/pages/account/course.vue?macro=true";
import { default as examnjviGgOUYkMeta } from "D:/workspace/web/roncoo-web/pages/account/exam.vue?macro=true";
import { default as generalexamUXz9kgQasaMeta } from "D:/workspace/web/roncoo-web/pages/account/generalexam.vue?macro=true";
import { default as gradety34EuHVU8Meta } from "D:/workspace/web/roncoo-web/pages/account/grade.vue?macro=true";
import { default as homeworkrkGJ15adt3Meta } from "D:/workspace/web/roncoo-web/pages/account/homework.vue?macro=true";
import { default as infooWUIH6sv9LMeta } from "D:/workspace/web/roncoo-web/pages/account/info.vue?macro=true";
import { default as indexvdg2Oyk9iDMeta } from "D:/workspace/web/roncoo-web/pages/account/message/index.vue?macro=true";
import { default as notebhW4UfeZCOMeta } from "D:/workspace/web/roncoo-web/pages/account/note.vue?macro=true";
import { default as indexPEIcnUwVMlMeta } from "D:/workspace/web/roncoo-web/pages/account/order/index.vue?macro=true";
import { default as paperQ0OyGpyNW9Meta } from "D:/workspace/web/roncoo-web/pages/account/paper.vue?macro=true";
import { default as scoren8ENSzv29xMeta } from "D:/workspace/web/roncoo-web/pages/account/score.vue?macro=true";
import { default as thesisiUCFoSESG6Meta } from "D:/workspace/web/roncoo-web/pages/account/thesis.vue?macro=true";
import { default as aiSayja7AGswFI9Meta } from "D:/workspace/web/roncoo-web/pages/ai/aiSay.vue?macro=true";
import { default as aiTalkJRPRFNThjnMeta } from "D:/workspace/web/roncoo-web/pages/ai/aiTalk.vue?macro=true";
import { default as listIiqPngeBpXMeta } from "D:/workspace/web/roncoo-web/pages/article/list.vue?macro=true";
import { default as viewe4nveSU6zNMeta } from "D:/workspace/web/roncoo-web/pages/article/view.vue?macro=true";
import { default as audio5o8eclwiztMeta } from "D:/workspace/web/roncoo-web/pages/course/audio.vue?macro=true";
import { default as combinemtHIusEcK2Meta } from "D:/workspace/web/roncoo-web/pages/course/combine.vue?macro=true";
import { default as contractDialogx6DUtEQ0XOMeta } from "D:/workspace/web/roncoo-web/pages/course/contractDialog.vue?macro=true";
import { default as graphicun1kGcpeG9Meta } from "D:/workspace/web/roncoo-web/pages/course/graphic.vue?macro=true";
import { default as list7QxVTmXmPNMeta } from "D:/workspace/web/roncoo-web/pages/course/list.vue?macro=true";
import { default as livecNQsLBVmkyMeta } from "D:/workspace/web/roncoo-web/pages/course/live.vue?macro=true";
import { default as singleEfgiA1nxb6Meta } from "D:/workspace/web/roncoo-web/pages/course/single.vue?macro=true";
import { default as subjectOEKb92RgAZMeta } from "D:/workspace/web/roncoo-web/pages/course/subject.vue?macro=true";
import { default as topicjtiF8FHsQCMeta } from "D:/workspace/web/roncoo-web/pages/course/topic.vue?macro=true";
import { default as videoa0b8RrnjfIMeta } from "D:/workspace/web/roncoo-web/pages/course/video.vue?macro=true";
import { default as EntrancetestHead6z4wM3SctnMeta } from "D:/workspace/web/roncoo-web/pages/entrancetest/EntrancetestHead.vue?macro=true";
import { default as examjUPXSA7aF7Meta } from "D:/workspace/web/roncoo-web/pages/entrancetest/exam.vue?macro=true";
import { default as indexf9RBjSdXomMeta } from "D:/workspace/web/roncoo-web/pages/entrancetest/index.vue?macro=true";
import { default as analysisO6IkpuH3MQMeta } from "D:/workspace/web/roncoo-web/pages/exam/analysis.vue?macro=true";
import { default as emigratedjTKjPqyxFqMeta } from "D:/workspace/web/roncoo-web/pages/exam/emigrated.vue?macro=true";
import { default as indexPikXGVmuPjMeta } from "D:/workspace/web/roncoo-web/pages/exam/index.vue?macro=true";
import { default as listqeily7FUrwMeta } from "D:/workspace/web/roncoo-web/pages/exam/list.vue?macro=true";
import { default as resultFrHsUx1xExMeta } from "D:/workspace/web/roncoo-web/pages/exam/result.vue?macro=true";
import { default as startExamlvIl55CxdoMeta } from "D:/workspace/web/roncoo-web/pages/exam/startExam.vue?macro=true";
import { default as homeworkmfaUsXBnmeMeta } from "D:/workspace/web/roncoo-web/pages/grade/homework.vue?macro=true";
import { default as listRliRIA6SJ9Meta } from "D:/workspace/web/roncoo-web/pages/grade/list.vue?macro=true";
import { default as materiali1YxlbMxloMeta } from "D:/workspace/web/roncoo-web/pages/grade/material.vue?macro=true";
import { default as practicepMkqEov3QAMeta } from "D:/workspace/web/roncoo-web/pages/grade/practice.vue?macro=true";
import { default as viewoKbGSAgAopMeta } from "D:/workspace/web/roncoo-web/pages/grade/view.vue?macro=true";
import { default as indexHsiBveDvkiMeta } from "D:/workspace/web/roncoo-web/pages/index.vue?macro=true";
import { default as agreementLi9bwmeDQgMeta } from "D:/workspace/web/roncoo-web/pages/login/agreement.vue?macro=true";
import { default as indexwsLWMjq9MYMeta } from "D:/workspace/web/roncoo-web/pages/login/index.vue?macro=true";
import { default as childrenListjUpcKTdsnUMeta } from "D:/workspace/web/roncoo-web/pages/paper/childrenList.vue?macro=true";
import { default as emigratedAWuEYT1fuJMeta } from "D:/workspace/web/roncoo-web/pages/paper/emigrated.vue?macro=true";
import { default as indexuA60zoGUErMeta } from "D:/workspace/web/roncoo-web/pages/paper/index.vue?macro=true";
import { default as listlFmOzspYECMeta } from "D:/workspace/web/roncoo-web/pages/paper/list.vue?macro=true";
import { default as vieweoUlhxC5VlMeta } from "D:/workspace/web/roncoo-web/pages/paper/view.vue?macro=true";
import { default as buy1Uw3sTWfNJMeta } from "D:/workspace/web/roncoo-web/pages/pay/buy.vue?macro=true";
import { default as paySuccessjccFxg9WTKMeta } from "D:/workspace/web/roncoo-web/pages/pay/paySuccess.vue?macro=true";
import { default as analysisNWSoT2zIJ0Meta } from "D:/workspace/web/roncoo-web/pages/practice/analysis.vue?macro=true";
import { default as dailydaQ7153TixMeta } from "D:/workspace/web/roncoo-web/pages/practice/daily.vue?macro=true";
import { default as dailyProblemZ47on0LXkEMeta } from "D:/workspace/web/roncoo-web/pages/practice/dailyProblem.vue?macro=true";
import { default as error8dkAq4Tn3SMeta } from "D:/workspace/web/roncoo-web/pages/practice/error.vue?macro=true";
import { default as errorAnalysissjK1CVO2bjMeta } from "D:/workspace/web/roncoo-web/pages/practice/errorAnalysis.vue?macro=true";
import { default as errorProblemLEJuM0C4rqMeta } from "D:/workspace/web/roncoo-web/pages/practice/errorProblem.vue?macro=true";
import { default as errorResultRDglVSYs65Meta } from "D:/workspace/web/roncoo-web/pages/practice/errorResult.vue?macro=true";
import { default as resulteLviTUr7KkMeta } from "D:/workspace/web/roncoo-web/pages/practice/result.vue?macro=true";
import { default as startPracticeeW0TCdmFQ8Meta } from "D:/workspace/web/roncoo-web/pages/practice/startPractice.vue?macro=true";
import { default as viewG8wtAOB3TJMeta } from "D:/workspace/web/roncoo-web/pages/practice/view.vue?macro=true";
import { default as indexdISmOo6uTTMeta } from "D:/workspace/web/roncoo-web/pages/register/index.vue?macro=true";
import { default as indexTJ0WVJk0bFMeta } from "D:/workspace/web/roncoo-web/pages/resetpwd/index.vue?macro=true";
import { default as indexeoCzO4XQhlMeta } from "D:/workspace/web/roncoo-web/pages/search/index.vue?macro=true";
import { default as indexvt5ZhjTp4xMeta } from "D:/workspace/web/roncoo-web/pages/settlement/index.vue?macro=true";
import { default as listkIUzdyIZd0Meta } from "D:/workspace/web/roncoo-web/pages/teacher/list.vue?macro=true";
import { default as viewvoB9PQMdtoMeta } from "D:/workspace/web/roncoo-web/pages/teacher/view.vue?macro=true";
import { default as indexEuxc20W2toMeta } from "D:/workspace/web/roncoo-web/pages/vip/index.vue?macro=true";
import { default as successuyJSv0ieAUMeta } from "D:/workspace/web/roncoo-web/pages/wx/success.vue?macro=true";
import { default as wxVLMvUOeKsNMeta } from "D:/workspace/web/roncoo-web/pages/wx/wx.vue?macro=true";
export default [
  {
    name: certificateL7co0lEgP4Meta?.name ?? "account-certificate",
    path: certificateL7co0lEgP4Meta?.path ?? "/account/certificate",
    meta: certificateL7co0lEgP4Meta || {},
    alias: certificateL7co0lEgP4Meta?.alias || [],
    redirect: certificateL7co0lEgP4Meta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/account/certificate.vue").then(m => m.default || m)
  },
  {
    name: collectIqmM5CfUO2Meta?.name ?? "account-collect",
    path: collectIqmM5CfUO2Meta?.path ?? "/account/collect",
    meta: collectIqmM5CfUO2Meta || {},
    alias: collectIqmM5CfUO2Meta?.alias || [],
    redirect: collectIqmM5CfUO2Meta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/account/collect.vue").then(m => m.default || m)
  },
  {
    name: couponLFC6dQyLrJMeta?.name ?? "account-coupon",
    path: couponLFC6dQyLrJMeta?.path ?? "/account/coupon",
    meta: couponLFC6dQyLrJMeta || {},
    alias: couponLFC6dQyLrJMeta?.alias || [],
    redirect: couponLFC6dQyLrJMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/account/coupon.vue").then(m => m.default || m)
  },
  {
    name: course6Swd6tPaDAMeta?.name ?? "account-course",
    path: course6Swd6tPaDAMeta?.path ?? "/account/course",
    meta: course6Swd6tPaDAMeta || {},
    alias: course6Swd6tPaDAMeta?.alias || [],
    redirect: course6Swd6tPaDAMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/account/course.vue").then(m => m.default || m)
  },
  {
    name: examnjviGgOUYkMeta?.name ?? "account-exam",
    path: examnjviGgOUYkMeta?.path ?? "/account/exam",
    meta: examnjviGgOUYkMeta || {},
    alias: examnjviGgOUYkMeta?.alias || [],
    redirect: examnjviGgOUYkMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/account/exam.vue").then(m => m.default || m)
  },
  {
    name: generalexamUXz9kgQasaMeta?.name ?? "account-generalexam",
    path: generalexamUXz9kgQasaMeta?.path ?? "/account/generalexam",
    meta: generalexamUXz9kgQasaMeta || {},
    alias: generalexamUXz9kgQasaMeta?.alias || [],
    redirect: generalexamUXz9kgQasaMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/account/generalexam.vue").then(m => m.default || m)
  },
  {
    name: gradety34EuHVU8Meta?.name ?? "account-grade",
    path: gradety34EuHVU8Meta?.path ?? "/account/grade",
    meta: gradety34EuHVU8Meta || {},
    alias: gradety34EuHVU8Meta?.alias || [],
    redirect: gradety34EuHVU8Meta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/account/grade.vue").then(m => m.default || m)
  },
  {
    name: homeworkrkGJ15adt3Meta?.name ?? "account-homework",
    path: homeworkrkGJ15adt3Meta?.path ?? "/account/homework",
    meta: homeworkrkGJ15adt3Meta || {},
    alias: homeworkrkGJ15adt3Meta?.alias || [],
    redirect: homeworkrkGJ15adt3Meta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/account/homework.vue").then(m => m.default || m)
  },
  {
    name: infooWUIH6sv9LMeta?.name ?? "account-info",
    path: infooWUIH6sv9LMeta?.path ?? "/account/info",
    meta: infooWUIH6sv9LMeta || {},
    alias: infooWUIH6sv9LMeta?.alias || [],
    redirect: infooWUIH6sv9LMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/account/info.vue").then(m => m.default || m)
  },
  {
    name: indexvdg2Oyk9iDMeta?.name ?? "account-message",
    path: indexvdg2Oyk9iDMeta?.path ?? "/account/message",
    meta: indexvdg2Oyk9iDMeta || {},
    alias: indexvdg2Oyk9iDMeta?.alias || [],
    redirect: indexvdg2Oyk9iDMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/account/message/index.vue").then(m => m.default || m)
  },
  {
    name: notebhW4UfeZCOMeta?.name ?? "account-note",
    path: notebhW4UfeZCOMeta?.path ?? "/account/note",
    meta: notebhW4UfeZCOMeta || {},
    alias: notebhW4UfeZCOMeta?.alias || [],
    redirect: notebhW4UfeZCOMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/account/note.vue").then(m => m.default || m)
  },
  {
    name: indexPEIcnUwVMlMeta?.name ?? "account-order",
    path: indexPEIcnUwVMlMeta?.path ?? "/account/order",
    meta: indexPEIcnUwVMlMeta || {},
    alias: indexPEIcnUwVMlMeta?.alias || [],
    redirect: indexPEIcnUwVMlMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: paperQ0OyGpyNW9Meta?.name ?? "account-paper",
    path: paperQ0OyGpyNW9Meta?.path ?? "/account/paper",
    meta: paperQ0OyGpyNW9Meta || {},
    alias: paperQ0OyGpyNW9Meta?.alias || [],
    redirect: paperQ0OyGpyNW9Meta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/account/paper.vue").then(m => m.default || m)
  },
  {
    name: scoren8ENSzv29xMeta?.name ?? "account-score",
    path: scoren8ENSzv29xMeta?.path ?? "/account/score",
    meta: scoren8ENSzv29xMeta || {},
    alias: scoren8ENSzv29xMeta?.alias || [],
    redirect: scoren8ENSzv29xMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/account/score.vue").then(m => m.default || m)
  },
  {
    name: thesisiUCFoSESG6Meta?.name ?? "account-thesis",
    path: thesisiUCFoSESG6Meta?.path ?? "/account/thesis",
    meta: thesisiUCFoSESG6Meta || {},
    alias: thesisiUCFoSESG6Meta?.alias || [],
    redirect: thesisiUCFoSESG6Meta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/account/thesis.vue").then(m => m.default || m)
  },
  {
    name: aiSayja7AGswFI9Meta?.name ?? "ai-aiSay",
    path: aiSayja7AGswFI9Meta?.path ?? "/ai/aiSay",
    meta: aiSayja7AGswFI9Meta || {},
    alias: aiSayja7AGswFI9Meta?.alias || [],
    redirect: aiSayja7AGswFI9Meta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/ai/aiSay.vue").then(m => m.default || m)
  },
  {
    name: aiTalkJRPRFNThjnMeta?.name ?? "ai-aiTalk",
    path: aiTalkJRPRFNThjnMeta?.path ?? "/ai/aiTalk",
    meta: aiTalkJRPRFNThjnMeta || {},
    alias: aiTalkJRPRFNThjnMeta?.alias || [],
    redirect: aiTalkJRPRFNThjnMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/ai/aiTalk.vue").then(m => m.default || m)
  },
  {
    name: listIiqPngeBpXMeta?.name ?? "article-list",
    path: listIiqPngeBpXMeta?.path ?? "/article/list",
    meta: listIiqPngeBpXMeta || {},
    alias: listIiqPngeBpXMeta?.alias || [],
    redirect: listIiqPngeBpXMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/article/list.vue").then(m => m.default || m)
  },
  {
    name: viewe4nveSU6zNMeta?.name ?? "article-view",
    path: viewe4nveSU6zNMeta?.path ?? "/article/view",
    meta: viewe4nveSU6zNMeta || {},
    alias: viewe4nveSU6zNMeta?.alias || [],
    redirect: viewe4nveSU6zNMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/article/view.vue").then(m => m.default || m)
  },
  {
    name: audio5o8eclwiztMeta?.name ?? "course-audio",
    path: audio5o8eclwiztMeta?.path ?? "/course/audio",
    meta: audio5o8eclwiztMeta || {},
    alias: audio5o8eclwiztMeta?.alias || [],
    redirect: audio5o8eclwiztMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/course/audio.vue").then(m => m.default || m)
  },
  {
    name: combinemtHIusEcK2Meta?.name ?? "course-combine",
    path: combinemtHIusEcK2Meta?.path ?? "/course/combine",
    meta: combinemtHIusEcK2Meta || {},
    alias: combinemtHIusEcK2Meta?.alias || [],
    redirect: combinemtHIusEcK2Meta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/course/combine.vue").then(m => m.default || m)
  },
  {
    name: contractDialogx6DUtEQ0XOMeta?.name ?? "course-contractDialog",
    path: contractDialogx6DUtEQ0XOMeta?.path ?? "/course/contractDialog",
    meta: contractDialogx6DUtEQ0XOMeta || {},
    alias: contractDialogx6DUtEQ0XOMeta?.alias || [],
    redirect: contractDialogx6DUtEQ0XOMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/course/contractDialog.vue").then(m => m.default || m)
  },
  {
    name: graphicun1kGcpeG9Meta?.name ?? "course-graphic",
    path: graphicun1kGcpeG9Meta?.path ?? "/course/graphic",
    meta: graphicun1kGcpeG9Meta || {},
    alias: graphicun1kGcpeG9Meta?.alias || [],
    redirect: graphicun1kGcpeG9Meta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/course/graphic.vue").then(m => m.default || m)
  },
  {
    name: list7QxVTmXmPNMeta?.name ?? "course-list",
    path: list7QxVTmXmPNMeta?.path ?? "/course/list",
    meta: list7QxVTmXmPNMeta || {},
    alias: list7QxVTmXmPNMeta?.alias || [],
    redirect: list7QxVTmXmPNMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/course/list.vue").then(m => m.default || m)
  },
  {
    name: livecNQsLBVmkyMeta?.name ?? "course-live",
    path: livecNQsLBVmkyMeta?.path ?? "/course/live",
    meta: livecNQsLBVmkyMeta || {},
    alias: livecNQsLBVmkyMeta?.alias || [],
    redirect: livecNQsLBVmkyMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/course/live.vue").then(m => m.default || m)
  },
  {
    name: singleEfgiA1nxb6Meta?.name ?? "course-single",
    path: singleEfgiA1nxb6Meta?.path ?? "/course/single",
    meta: singleEfgiA1nxb6Meta || {},
    alias: singleEfgiA1nxb6Meta?.alias || [],
    redirect: singleEfgiA1nxb6Meta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/course/single.vue").then(m => m.default || m)
  },
  {
    name: subjectOEKb92RgAZMeta?.name ?? "course-subject",
    path: subjectOEKb92RgAZMeta?.path ?? "/course/subject",
    meta: subjectOEKb92RgAZMeta || {},
    alias: subjectOEKb92RgAZMeta?.alias || [],
    redirect: subjectOEKb92RgAZMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/course/subject.vue").then(m => m.default || m)
  },
  {
    name: topicjtiF8FHsQCMeta?.name ?? "course-topic",
    path: topicjtiF8FHsQCMeta?.path ?? "/course/topic",
    meta: topicjtiF8FHsQCMeta || {},
    alias: topicjtiF8FHsQCMeta?.alias || [],
    redirect: topicjtiF8FHsQCMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/course/topic.vue").then(m => m.default || m)
  },
  {
    name: videoa0b8RrnjfIMeta?.name ?? "course-video",
    path: videoa0b8RrnjfIMeta?.path ?? "/course/video",
    meta: videoa0b8RrnjfIMeta || {},
    alias: videoa0b8RrnjfIMeta?.alias || [],
    redirect: videoa0b8RrnjfIMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/course/video.vue").then(m => m.default || m)
  },
  {
    name: EntrancetestHead6z4wM3SctnMeta?.name ?? "entrancetest-EntrancetestHead",
    path: EntrancetestHead6z4wM3SctnMeta?.path ?? "/entrancetest/EntrancetestHead",
    meta: EntrancetestHead6z4wM3SctnMeta || {},
    alias: EntrancetestHead6z4wM3SctnMeta?.alias || [],
    redirect: EntrancetestHead6z4wM3SctnMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/entrancetest/EntrancetestHead.vue").then(m => m.default || m)
  },
  {
    name: examjUPXSA7aF7Meta?.name ?? "entrancetest-exam",
    path: examjUPXSA7aF7Meta?.path ?? "/entrancetest/exam",
    meta: examjUPXSA7aF7Meta || {},
    alias: examjUPXSA7aF7Meta?.alias || [],
    redirect: examjUPXSA7aF7Meta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/entrancetest/exam.vue").then(m => m.default || m)
  },
  {
    name: indexf9RBjSdXomMeta?.name ?? "entrancetest",
    path: indexf9RBjSdXomMeta?.path ?? "/entrancetest",
    meta: indexf9RBjSdXomMeta || {},
    alias: indexf9RBjSdXomMeta?.alias || [],
    redirect: indexf9RBjSdXomMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/entrancetest/index.vue").then(m => m.default || m)
  },
  {
    name: analysisO6IkpuH3MQMeta?.name ?? "exam-analysis",
    path: analysisO6IkpuH3MQMeta?.path ?? "/exam/analysis",
    meta: analysisO6IkpuH3MQMeta || {},
    alias: analysisO6IkpuH3MQMeta?.alias || [],
    redirect: analysisO6IkpuH3MQMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/exam/analysis.vue").then(m => m.default || m)
  },
  {
    name: emigratedjTKjPqyxFqMeta?.name ?? "exam-emigrated",
    path: emigratedjTKjPqyxFqMeta?.path ?? "/exam/emigrated",
    meta: emigratedjTKjPqyxFqMeta || {},
    alias: emigratedjTKjPqyxFqMeta?.alias || [],
    redirect: emigratedjTKjPqyxFqMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/exam/emigrated.vue").then(m => m.default || m)
  },
  {
    name: indexPikXGVmuPjMeta?.name ?? "exam",
    path: indexPikXGVmuPjMeta?.path ?? "/exam",
    meta: indexPikXGVmuPjMeta || {},
    alias: indexPikXGVmuPjMeta?.alias || [],
    redirect: indexPikXGVmuPjMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/exam/index.vue").then(m => m.default || m)
  },
  {
    name: listqeily7FUrwMeta?.name ?? "exam-list",
    path: listqeily7FUrwMeta?.path ?? "/exam/list",
    meta: listqeily7FUrwMeta || {},
    alias: listqeily7FUrwMeta?.alias || [],
    redirect: listqeily7FUrwMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/exam/list.vue").then(m => m.default || m)
  },
  {
    name: resultFrHsUx1xExMeta?.name ?? "exam-result",
    path: resultFrHsUx1xExMeta?.path ?? "/exam/result",
    meta: resultFrHsUx1xExMeta || {},
    alias: resultFrHsUx1xExMeta?.alias || [],
    redirect: resultFrHsUx1xExMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/exam/result.vue").then(m => m.default || m)
  },
  {
    name: startExamlvIl55CxdoMeta?.name ?? "exam-startExam",
    path: startExamlvIl55CxdoMeta?.path ?? "/exam/startExam",
    meta: startExamlvIl55CxdoMeta || {},
    alias: startExamlvIl55CxdoMeta?.alias || [],
    redirect: startExamlvIl55CxdoMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/exam/startExam.vue").then(m => m.default || m)
  },
  {
    name: homeworkmfaUsXBnmeMeta?.name ?? "grade-homework",
    path: homeworkmfaUsXBnmeMeta?.path ?? "/grade/homework",
    meta: homeworkmfaUsXBnmeMeta || {},
    alias: homeworkmfaUsXBnmeMeta?.alias || [],
    redirect: homeworkmfaUsXBnmeMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/grade/homework.vue").then(m => m.default || m)
  },
  {
    name: listRliRIA6SJ9Meta?.name ?? "grade-list",
    path: listRliRIA6SJ9Meta?.path ?? "/grade/list",
    meta: listRliRIA6SJ9Meta || {},
    alias: listRliRIA6SJ9Meta?.alias || [],
    redirect: listRliRIA6SJ9Meta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/grade/list.vue").then(m => m.default || m)
  },
  {
    name: materiali1YxlbMxloMeta?.name ?? "grade-material",
    path: materiali1YxlbMxloMeta?.path ?? "/grade/material",
    meta: materiali1YxlbMxloMeta || {},
    alias: materiali1YxlbMxloMeta?.alias || [],
    redirect: materiali1YxlbMxloMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/grade/material.vue").then(m => m.default || m)
  },
  {
    name: practicepMkqEov3QAMeta?.name ?? "grade-practice",
    path: practicepMkqEov3QAMeta?.path ?? "/grade/practice",
    meta: practicepMkqEov3QAMeta || {},
    alias: practicepMkqEov3QAMeta?.alias || [],
    redirect: practicepMkqEov3QAMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/grade/practice.vue").then(m => m.default || m)
  },
  {
    name: viewoKbGSAgAopMeta?.name ?? "grade-view",
    path: viewoKbGSAgAopMeta?.path ?? "/grade/view",
    meta: viewoKbGSAgAopMeta || {},
    alias: viewoKbGSAgAopMeta?.alias || [],
    redirect: viewoKbGSAgAopMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/grade/view.vue").then(m => m.default || m)
  },
  {
    name: indexHsiBveDvkiMeta?.name ?? "index",
    path: indexHsiBveDvkiMeta?.path ?? "/",
    meta: indexHsiBveDvkiMeta || {},
    alias: indexHsiBveDvkiMeta?.alias || [],
    redirect: indexHsiBveDvkiMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: agreementLi9bwmeDQgMeta?.name ?? "login-agreement",
    path: agreementLi9bwmeDQgMeta?.path ?? "/login/agreement",
    meta: agreementLi9bwmeDQgMeta || {},
    alias: agreementLi9bwmeDQgMeta?.alias || [],
    redirect: agreementLi9bwmeDQgMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/login/agreement.vue").then(m => m.default || m)
  },
  {
    name: indexwsLWMjq9MYMeta?.name ?? "login",
    path: indexwsLWMjq9MYMeta?.path ?? "/login",
    meta: indexwsLWMjq9MYMeta || {},
    alias: indexwsLWMjq9MYMeta?.alias || [],
    redirect: indexwsLWMjq9MYMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: childrenListjUpcKTdsnUMeta?.name ?? "paper-childrenList",
    path: childrenListjUpcKTdsnUMeta?.path ?? "/paper/childrenList",
    meta: childrenListjUpcKTdsnUMeta || {},
    alias: childrenListjUpcKTdsnUMeta?.alias || [],
    redirect: childrenListjUpcKTdsnUMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/paper/childrenList.vue").then(m => m.default || m)
  },
  {
    name: emigratedAWuEYT1fuJMeta?.name ?? "paper-emigrated",
    path: emigratedAWuEYT1fuJMeta?.path ?? "/paper/emigrated",
    meta: emigratedAWuEYT1fuJMeta || {},
    alias: emigratedAWuEYT1fuJMeta?.alias || [],
    redirect: emigratedAWuEYT1fuJMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/paper/emigrated.vue").then(m => m.default || m)
  },
  {
    name: indexuA60zoGUErMeta?.name ?? "paper",
    path: indexuA60zoGUErMeta?.path ?? "/paper",
    meta: indexuA60zoGUErMeta || {},
    alias: indexuA60zoGUErMeta?.alias || [],
    redirect: indexuA60zoGUErMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/paper/index.vue").then(m => m.default || m)
  },
  {
    name: listlFmOzspYECMeta?.name ?? "paper-list",
    path: listlFmOzspYECMeta?.path ?? "/paper/list",
    meta: listlFmOzspYECMeta || {},
    alias: listlFmOzspYECMeta?.alias || [],
    redirect: listlFmOzspYECMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/paper/list.vue").then(m => m.default || m)
  },
  {
    name: vieweoUlhxC5VlMeta?.name ?? "paper-view",
    path: vieweoUlhxC5VlMeta?.path ?? "/paper/view",
    meta: vieweoUlhxC5VlMeta || {},
    alias: vieweoUlhxC5VlMeta?.alias || [],
    redirect: vieweoUlhxC5VlMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/paper/view.vue").then(m => m.default || m)
  },
  {
    name: buy1Uw3sTWfNJMeta?.name ?? "pay-buy",
    path: buy1Uw3sTWfNJMeta?.path ?? "/pay/buy",
    meta: buy1Uw3sTWfNJMeta || {},
    alias: buy1Uw3sTWfNJMeta?.alias || [],
    redirect: buy1Uw3sTWfNJMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/pay/buy.vue").then(m => m.default || m)
  },
  {
    name: paySuccessjccFxg9WTKMeta?.name ?? "pay-paySuccess",
    path: paySuccessjccFxg9WTKMeta?.path ?? "/pay/paySuccess",
    meta: paySuccessjccFxg9WTKMeta || {},
    alias: paySuccessjccFxg9WTKMeta?.alias || [],
    redirect: paySuccessjccFxg9WTKMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/pay/paySuccess.vue").then(m => m.default || m)
  },
  {
    name: analysisNWSoT2zIJ0Meta?.name ?? "practice-analysis",
    path: analysisNWSoT2zIJ0Meta?.path ?? "/practice/analysis",
    meta: analysisNWSoT2zIJ0Meta || {},
    alias: analysisNWSoT2zIJ0Meta?.alias || [],
    redirect: analysisNWSoT2zIJ0Meta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/practice/analysis.vue").then(m => m.default || m)
  },
  {
    name: dailydaQ7153TixMeta?.name ?? "practice-daily",
    path: dailydaQ7153TixMeta?.path ?? "/practice/daily",
    meta: dailydaQ7153TixMeta || {},
    alias: dailydaQ7153TixMeta?.alias || [],
    redirect: dailydaQ7153TixMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/practice/daily.vue").then(m => m.default || m)
  },
  {
    name: dailyProblemZ47on0LXkEMeta?.name ?? "practice-dailyProblem",
    path: dailyProblemZ47on0LXkEMeta?.path ?? "/practice/dailyProblem",
    meta: dailyProblemZ47on0LXkEMeta || {},
    alias: dailyProblemZ47on0LXkEMeta?.alias || [],
    redirect: dailyProblemZ47on0LXkEMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/practice/dailyProblem.vue").then(m => m.default || m)
  },
  {
    name: error8dkAq4Tn3SMeta?.name ?? "practice-error",
    path: error8dkAq4Tn3SMeta?.path ?? "/practice/error",
    meta: error8dkAq4Tn3SMeta || {},
    alias: error8dkAq4Tn3SMeta?.alias || [],
    redirect: error8dkAq4Tn3SMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/practice/error.vue").then(m => m.default || m)
  },
  {
    name: errorAnalysissjK1CVO2bjMeta?.name ?? "practice-errorAnalysis",
    path: errorAnalysissjK1CVO2bjMeta?.path ?? "/practice/errorAnalysis",
    meta: errorAnalysissjK1CVO2bjMeta || {},
    alias: errorAnalysissjK1CVO2bjMeta?.alias || [],
    redirect: errorAnalysissjK1CVO2bjMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/practice/errorAnalysis.vue").then(m => m.default || m)
  },
  {
    name: errorProblemLEJuM0C4rqMeta?.name ?? "practice-errorProblem",
    path: errorProblemLEJuM0C4rqMeta?.path ?? "/practice/errorProblem",
    meta: errorProblemLEJuM0C4rqMeta || {},
    alias: errorProblemLEJuM0C4rqMeta?.alias || [],
    redirect: errorProblemLEJuM0C4rqMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/practice/errorProblem.vue").then(m => m.default || m)
  },
  {
    name: errorResultRDglVSYs65Meta?.name ?? "practice-errorResult",
    path: errorResultRDglVSYs65Meta?.path ?? "/practice/errorResult",
    meta: errorResultRDglVSYs65Meta || {},
    alias: errorResultRDglVSYs65Meta?.alias || [],
    redirect: errorResultRDglVSYs65Meta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/practice/errorResult.vue").then(m => m.default || m)
  },
  {
    name: resulteLviTUr7KkMeta?.name ?? "practice-result",
    path: resulteLviTUr7KkMeta?.path ?? "/practice/result",
    meta: resulteLviTUr7KkMeta || {},
    alias: resulteLviTUr7KkMeta?.alias || [],
    redirect: resulteLviTUr7KkMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/practice/result.vue").then(m => m.default || m)
  },
  {
    name: startPracticeeW0TCdmFQ8Meta?.name ?? "practice-startPractice",
    path: startPracticeeW0TCdmFQ8Meta?.path ?? "/practice/startPractice",
    meta: startPracticeeW0TCdmFQ8Meta || {},
    alias: startPracticeeW0TCdmFQ8Meta?.alias || [],
    redirect: startPracticeeW0TCdmFQ8Meta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/practice/startPractice.vue").then(m => m.default || m)
  },
  {
    name: viewG8wtAOB3TJMeta?.name ?? "practice-view",
    path: viewG8wtAOB3TJMeta?.path ?? "/practice/view",
    meta: viewG8wtAOB3TJMeta || {},
    alias: viewG8wtAOB3TJMeta?.alias || [],
    redirect: viewG8wtAOB3TJMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/practice/view.vue").then(m => m.default || m)
  },
  {
    name: indexdISmOo6uTTMeta?.name ?? "register",
    path: indexdISmOo6uTTMeta?.path ?? "/register",
    meta: indexdISmOo6uTTMeta || {},
    alias: indexdISmOo6uTTMeta?.alias || [],
    redirect: indexdISmOo6uTTMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexTJ0WVJk0bFMeta?.name ?? "resetpwd",
    path: indexTJ0WVJk0bFMeta?.path ?? "/resetpwd",
    meta: indexTJ0WVJk0bFMeta || {},
    alias: indexTJ0WVJk0bFMeta?.alias || [],
    redirect: indexTJ0WVJk0bFMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/resetpwd/index.vue").then(m => m.default || m)
  },
  {
    name: indexeoCzO4XQhlMeta?.name ?? "search",
    path: indexeoCzO4XQhlMeta?.path ?? "/search",
    meta: indexeoCzO4XQhlMeta || {},
    alias: indexeoCzO4XQhlMeta?.alias || [],
    redirect: indexeoCzO4XQhlMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexvt5ZhjTp4xMeta?.name ?? "settlement",
    path: indexvt5ZhjTp4xMeta?.path ?? "/settlement",
    meta: indexvt5ZhjTp4xMeta || {},
    alias: indexvt5ZhjTp4xMeta?.alias || [],
    redirect: indexvt5ZhjTp4xMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/settlement/index.vue").then(m => m.default || m)
  },
  {
    name: listkIUzdyIZd0Meta?.name ?? "teacher-list",
    path: listkIUzdyIZd0Meta?.path ?? "/teacher/list",
    meta: listkIUzdyIZd0Meta || {},
    alias: listkIUzdyIZd0Meta?.alias || [],
    redirect: listkIUzdyIZd0Meta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/teacher/list.vue").then(m => m.default || m)
  },
  {
    name: viewvoB9PQMdtoMeta?.name ?? "teacher-view",
    path: viewvoB9PQMdtoMeta?.path ?? "/teacher/view",
    meta: viewvoB9PQMdtoMeta || {},
    alias: viewvoB9PQMdtoMeta?.alias || [],
    redirect: viewvoB9PQMdtoMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/teacher/view.vue").then(m => m.default || m)
  },
  {
    name: indexEuxc20W2toMeta?.name ?? "vip",
    path: indexEuxc20W2toMeta?.path ?? "/vip",
    meta: indexEuxc20W2toMeta || {},
    alias: indexEuxc20W2toMeta?.alias || [],
    redirect: indexEuxc20W2toMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/vip/index.vue").then(m => m.default || m)
  },
  {
    name: successuyJSv0ieAUMeta?.name ?? "wx-success",
    path: successuyJSv0ieAUMeta?.path ?? "/wx/success",
    meta: successuyJSv0ieAUMeta || {},
    alias: successuyJSv0ieAUMeta?.alias || [],
    redirect: successuyJSv0ieAUMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/wx/success.vue").then(m => m.default || m)
  },
  {
    name: wxVLMvUOeKsNMeta?.name ?? "wx-wx",
    path: wxVLMvUOeKsNMeta?.path ?? "/wx/wx",
    meta: wxVLMvUOeKsNMeta || {},
    alias: wxVLMvUOeKsNMeta?.alias || [],
    redirect: wxVLMvUOeKsNMeta?.redirect || undefined,
    component: () => import("D:/workspace/web/roncoo-web/pages/wx/wx.vue").then(m => m.default || m)
  }
]